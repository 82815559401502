<script>
import {
  required,
  // email,
  //   minLength,
  // sameAs,
  //   maxLength,
  // minValue,
  // maxValue,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import tableData from "@/components/tablecomponent";
// import { LGeoJson } from "vue2-leaflet";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    // tableData,
    // Switches,
    // NumberInputSpinner,*/
  },
  page: {
    title: "เพิ่มงานนอก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed: {
    /**
     * Total no. of records
     */
  },

  created() {
    this.getLocalData();
    this.showBranchOS();
    // this.getdataService();
  },

  data() {
    return {
      showOverlayFormOS: false,
      submitformOS: false,

      title: "ใบรับสั่งงานนอก",
      items: [
        {
          text: "บริการ",
        },
        {
          text: "รับสั่งงานนอก",
        },
        {
          text: "แก้ไขใบรับสั่งงานนอก",
        },
      ],
      stepOS: false,
      rowOS:[],
      recOsId: "",
      serviceSelect: [],
      amount: 1,
      dcPer: 0,
      dcPrice: 0,
      vatPer: 7,
      vatPrice: 0,
      dcPerOS: 0,
      statusOS: "DFT",

      loading: undefined,
      optionsSub: [],
      localDataBranch: [],
      localData: [],
      svId: "",
      roId: "",
      rowSv: [],
      rowRo: [],
      optionsService: [],
      osTotalPrice: 0,
      osDcPrice: 0,
      osNetPrice: 0,
      osVatPrice: 0,
      osGrandTotalPrice: 0,
      netPer: 0,
      branchId:'',

      rowService: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "osCode",
          label: "รหัสงานนอก",
        },
        {
          key: "nameTh",
          label: "ชื่องานนอก",
        },
        {
          key: "price",
          label: "ราคา/หน่วย",
        },
        {
          key: "amount",
          label: "จำนวน",
        },
        {
          key: "totalPrice",
          label: "รวม",
        },
        {
          key: "dcPer",
          label: "ส่วนลด(%)",
        },
        {
          key: "dcPrice",
          label: "ลดรวม",
        },
        {
          key: "netPrice",
          label: "หลังลด",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      formOS: {
        outsourcingId: '',
        recOsDate: '',
      },
    };
  },
  validations: {
    formOS: {
      outsourcingId: {
        required,
      },
      recOsDate:{
        required
      }
      
    },
  },

  methods: {
    PutStatusOS() {
      useNetw
        .put("api/received-outsourcing/submit", {
          recOsId: this.$route.params.recOsId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.showBranchOS(); 
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.data),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },
    confirmPutstatusOS() {
      Swal.fire({
        title: "ต้องการยืนยันใบรับสั่งงานนอกใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.PutStatusOS();
        }
      });
    },
    putOS() {
      //? เสร็จสิ้น กลับสู้หน้าหลัก

      this.totalService();
      useNetw
        .put("api/received-outsourcing/update", {
          recOsId: this.recOsId,
          recOsDate: this.formOS.recOsDate,
          note: this.formOS.note,

          totalPrice: this.osTotalPrice,
          dcPer: this.dcPerOS,
          dcPrice: this.osDcPrice,
          netPrice: this.osNetPrice,
          vatPer: this.vatPer,
          vatPrice: this.osVatPrice,
          grandTotalPrice: parseFloat(this.osGrandTotalPrice),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          // this.getTotalPriceRT;
          this.$router.push({ name: "main-received-outsourcing" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.data),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },
    putService(
      osId,
      pricePerOne,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      vatPer,
      vatPrice,
      grandTotalPrice
    ) {
      this.totalService();
      // console.log(osId, pricePerOne,amount,totalPrice,dcPer,dcPrice,netPrice,vatPer,vatPrice,grandTotalPrice);
      useNetw
        .put("api/received-outsourcing/service/update", {
          recOsId: this.recOsId,
          osId: osId,
          amount: amount,
          pricePerOne: pricePerOne,
          totalPrice: totalPrice,
          dcPer: dcPer,
          dcPrice: dcPrice,
          netPrice: netPrice,
          vatPer: vatPer,
          vatPrice: vatPrice,
          grandTotalPrice: grandTotalPrice,
          // pickingAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,
          // pickingAccruedAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,

          recOsTotalPrice: this.osTotalPrice,
          recOsDcPer: this.dcPerOS,
          recOsDcPrice: this.osDcPrice,
          recOsNetPrice: this.osNetPrice,
          recOsVatPer: this.vatPer,
          recOsVatPrice: this.osVatPrice,
          recOsGrandTotalPrice: parseFloat(this.osGrandTotalPrice),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getdataService();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },
    calculateService(os) {
      let totalPriceCal = 0;
      let dcPriceCal = 0;
      let calNetPrice = 0;
      let calVatPrice = 0;
      let calGrandTotalPrice = 0;

      totalPriceCal = parseFloat(os.pricePerOne) * parseFloat(os.amount);
      if (!isNaN(totalPriceCal)) {
        os.totalPrice = totalPriceCal.toFixed(2);
      }
      os.totalPrice = totalPriceCal;

      dcPriceCal = parseFloat(os.totalPrice) * parseFloat(os.dcPer / 100);
      if (!isNaN(dcPriceCal)) {
        os.dcPrice = dcPriceCal.toFixed(2);
      }

      calNetPrice = parseFloat(os.totalPrice) - parseFloat(os.dcPrice);
      if (!isNaN(calNetPrice)) {
        os.netPrice = calNetPrice.toFixed(2);
      }

      calVatPrice = os.netPrice * parseFloat(this.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        os.vatPrice = calVatPrice.toFixed(2);
      }

      calGrandTotalPrice = parseFloat(os.netPrice) + parseFloat(os.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        os.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }
    },
    delService(row, index) {
      // this.selectDataPO = rowsRC.item;
      console.log("ROW DEL =>", row);
      this.rowService.splice(index, 1);

      // // console.log('RT ====> 0',rowRT);
      useNetw
        .delete("api/received-outsourcing/service/delete", {
          data: {
            recOsId: this.recOsId,
            osId: row.osId,

            recOsTotalPrice: this.osTotalPrice,
            recOsDcPer: this.dcPerOS,
            recOsDcPrice: this.osDcPrice,
            recOsNetPrice: this.osNetPrice,
            recOsVatPer: this.vatPer,
            recOsVatPrice: this.osVatPrice,
            recOsGrandTotalPrice: parseFloat(this.osGrandTotalPrice),
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getdataService();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },

    ConfirmDel(row, index) {
      // console.log(rowsRT[index]);
      // // console.log('DEL==>',rowsRC.item.poId);
      Swal.fire({
        title: "ต้องการยกเลิกงานนอกใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยิืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delService(row, index);
        }
      });
    },

    calTotal(os) {
      this.rowService.push(os);
      let sum = 0;
      this.rowService.forEach((item) => {
        sum = parseFloat(sum) + parseFloat(item.netPrice);
      });
      console.log("sum =>", sum);
      this.osTotalPrice = parseFloat(sum);
      this.osDcPrice = this.osTotalPrice * (this.dcPerOS / 100);
      this.osNetPrice =
        this.osTotalPrice - this.osTotalPrice * (this.dcPerOS / 100);
      this.osVatPrice = this.osNetPrice * (this.vatPer / 100);
      this.osGrandTotalPrice = this.osNetPrice * (1 + this.vatPer / 100);
    },
    totalService() {
      let sum = 0;
      this.rowService.forEach((item) => {
        sum = parseFloat(sum) + parseFloat(item.netPrice);
      });
      console.log("sum =>", sum);
      this.osTotalPrice = parseFloat(sum);
      this.osDcPrice = this.osTotalPrice * (this.dcPerOS / 100);
      this.osNetPrice =
        this.osTotalPrice - this.osTotalPrice * (this.dcPerOS / 100);
      this.osVatPrice = this.osNetPrice * (this.vatPer / 100);
      this.osGrandTotalPrice = this.osNetPrice * (1 + this.vatPer / 100);
    },
    getdataService() {
      this.showOverlayFormOS = true;
      useNetw
        .get("api/received-outsourcing/services", {
          params: {
            recOsId: this.recOsId,
          },
        })
        .then((response) => {
            console.log("OS in service", response.data.data);

          this.rowService = response.data.data;
          this.totalService();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.showOverlayFormOS = false;
          // this.overlayFlag = false;
        });
    },
    postService(dataOS) {
      this.serviceSelect = dataOS;

      const os = [];
      os.osId = this.serviceSelect.osId;
      os.nameTh = this.serviceSelect.nameTh;
      os.pricePerOne = this.serviceSelect.price;
      os.totalPrice = this.serviceSelect.price * this.amount;
      os.dcPer = this.dcPer;
      os.vatPer = this.vatPer;
      os.dcPrice = os.totalPrice * (this.dcPer / 100);
      os.netPrice = os.totalPrice - os.totalPrice * (this.dcPer / 100);
      os.vatPrice = os.netPrice * (this.vatPer / 100);
      os.grandTotalPrice = os.netPrice + os.netPrice * (this.vatPer / 100);
      //   os.grandTotalPrice = os.netPrice;

      this.calTotal(os);

      const osPost = new FormData();

      osPost.append("recOsId", this.recOsId);
      osPost.append("osId", os.osId);
      osPost.append("amount", this.amount);
      osPost.append("pricePerOne", os.pricePerOne);
      osPost.append("totalPrice", os.totalPrice);
      osPost.append("dcPer", this.dcPer);
      osPost.append("dcPrice", this.dcPrice);
      osPost.append("netPrice", os.netPrice);
      osPost.append("vatPer", this.vatPer);
      osPost.append("vatPrice", os.vatPrice);
      osPost.append("grandTotalPrice", os.grandTotalPrice);

      osPost.append("recOsTotalPrice", this.osTotalPrice);
      osPost.append("recOsDcPer", this.dcPerOS);
      osPost.append("recOsDcPrice", this.osDcPrice);
      osPost.append("recOsNetPrice", this.osNetPrice);
      osPost.append("recOsVatPer", this.vatPer);
      osPost.append("recOsVatPrice", this.osVatPrice);
      osPost.append(
        "recOsGrandTotalPrice",
        parseFloat(this.osGrandTotalPrice).toFixed(2)
      );

      useNetw
        .post("api/received-outsourcing/service/store", osPost)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getdataService();
          this.$refs.modalAddService.hide();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    popupmodalAddOS(data) {
      console.log("select Services=>", data);
      this.$refs.modalAddService.show();
    },
    customLabelService({ osCode, nameTh }) {
      return `[${osCode}] - ${nameTh}`;
    },
    showBranchOS() {
      this.isLoading = true;
      useNetw
        .get("api/received-outsourcing/show", {
          params: {
            recOsId: this.$route.params.recOsId,
          },
        })
        .then((response) => {
          this.recOsId = this.$route.params.recOsId;
          this.vatPer = response.data.data.vatPer;
          this.dcPer = response.data.data.dcPer;
          this.dcPerOS = response.data.data.dcPer;
          this.statusOS = response.data.data.status;
          this.branchId = response.data.data.branchId;
          this.formOS.recOsDate = response.data.data.recOsDate;
          this.formOS.note = response.data.data.note


          this.recOsId = this.$route.params.recOsId;
          this.vatPer = response.data.data.vatPer;
          this.dcPer = response.data.data.dcPer;
          this.dcPerOS = response.data.data.dcPer;
          this.statusOS = response.data.data.status;

          this.formOS.outsourcingId = {
            outsourcingId: response.data.data.branchId,
            outsourcingCode : response.data.data.outsourcingCode,
          };
          

          console.log('showBranchOS', response.data.data);
          this.getdataService();
          
          //   console.log('vatPer=',this.vatPer);
          //   console.log('dcPer=',this.dcPer);

          
          
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.getDataSv();
          this.getDataBranchService();
          this.isLoading = false;
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
    },
    formOSSubmit() {
      this.$v.$touch();
      if (this.$v.formOS.$invalid) {
        this.submitformOS = true;
      } else {
        this.postBranchOS();
      }
    },
    getSup: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId: this.branchId.branchId,
          },
        })
        .then((response) => {
          this.optionsSub = response.data.data;
          this.getDataSv();
          this.getDataBranchService();
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getDataBranchService: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/received-outsourcing/outsourcing-service", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.branchId.branchId,
          },
        })
        .then((response) => {
          this.optionsService = response.data.data;
          //   console.log("Service=>", this.optionsService);
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getDataSv() {
      useNetw
        .get("api/service", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.branchId.branchId,
          },
        })
        .then((response) => {
          this.rowSv = response.data.data;
          console.log("Sv=>", this.rowSv);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.isLoading = false;
        });
    },
    getDataRo() {
      useNetw
        .get("api/service/ro", {
          params: {
            page: 1,
            perPage: 100,
            svId: this.svId.svId,
          },
        })
        .then((response) => {
          this.rowRo = response.data.data;
          console.log("Ro=>", this.rowRo);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 my-auto"></div>
                <div class="col-md-6 text-end">
                  <router-link :to="{ name: 'main-received-outsourcing' }">
                    <button class="btn btn-secondary">กลับสู่หน้าหลัก</button>
                  </router-link>
                </div>
              </div>
              <hr />
              <form class="needs-validation" @submit.prevent="formOSSubmit()">
                <div class="row">
                  <div class="col-md-6">
                    
                        <code>* </code
                        ><label for="branchId">เลขใบสั่งงานนอก</label>
                        <multiselect
                          v-model="formOS.outsourcingId"
                          :options="rowOS"
                          label="outsourcingCode"
                          disabled
                          :class="{
                            'is-invalid':
                              submitformOS && $v.formOS.outsourcingId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitformOS && $v.formOS.outsourcingId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.formOS.outsourcingId.required">{{
                            error
                          }}</span>
                        </div>
                  </div>
                  <!-- <div class="col-md-3">
                    <label for="btn">&nbsp;</label><br>
                    <a
                          class="btn btn-primary"
                          @click="$refs.modalOS.show()"
                        >
                          <i class="uil uil-search"></i> เลือกจากใบสั่งงานนอก
                        </a>
                  </div> -->
                  <div class="col-3 col-sm-3 col-lg-3">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="validation">วันที่</label>
                      <date-picker
                      :disabled="statusOS != 'DFT'"
                        v-model="formOS.recOsDate"
                        format="YYYY-MM-DD"
                        value-type="format"
                        :class="{
                          'is-invalid':
                            submitformOS && $v.formOS.recOsDate.$error,
                        }"
                      ></date-picker>
                      <div
                        v-if="submitformOS && $v.formOS.recOsDate.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.recOsDate.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                <div class="row ">
                  <div class="col-md-6">
                    <label for="note">หมายเหตุ</label>
                    <b-form-textarea
                    :disabled="statusOS != 'DFT'"
                      v-model="formOS.note"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>
                </div>

                </div>
                <!--//! End Rows -->

                
                <hr />
              </form>
            </div>
          </div>
          <!-- //!end card -->
        </b-skeleton-wrapper>

        <div class="card">
          <div class="card-body">
            <h5>เพิ่มข้อมูลงานนอก</h5>
            <hr />
            <b-overlay
              :show="showOverlayFormOS"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสงานนอก</th>
                        <th scope="col">ชื่องานนอก</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.rowService.length > 0">
                      <tr
                        v-for="(rowService, index) in rowService"
                        :key="index"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ rowService.osCode }}</td>
                        <td>{{ rowService.osNameTh }}</td>
                        <td class="number">
                          <input
                            v-model="rowService.pricePerOne"
                            class="form-control text-end"
                            :disabled="statusOS != 'DFT'"
                            @keyup.enter="
                              putService(
                                rowService.osId,
                                rowService.pricePerOne,
                                rowService.amount,
                                rowService.totalPrice,
                                rowService.dcPer,
                                rowService.dcPrice,
                                rowService.netPrice,
                                rowService.vatPer,
                                rowService.vatPrice,
                                rowService.grandTotalPrice
                              )
                            "
                            @input="calculateService(rowService)"
                            @change="calculateService(rowService)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            v-model="rowService.amount"
                            class="form-control text-end"
                            :disabled="statusOS != 'DFT'"
                            @keyup.enter="
                              putService(
                                rowService.osId,
                                rowService.pricePerOne,
                                rowService.amount,
                                rowService.totalPrice,
                                rowService.dcPer,
                                rowService.dcPrice,
                                rowService.netPrice,
                                rowService.vatPer,
                                rowService.vatPrice,
                                rowService.grandTotalPrice
                              )
                            "
                            @input="calculateService(rowService)"
                            @change="calculateService(rowService)"
                          />
                        </td>
                        <td class="text-end">{{ rowService.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            v-model="rowService.dcPer"
                            class="form-control text-end"
                            :disabled="statusOS != 'DFT'"
                            @keyup.enter="
                              putService(
                                rowService.osId,
                                rowService.pricePerOne,
                                rowService.amount,
                                rowService.totalPrice,
                                rowService.dcPer,
                                rowService.dcPrice,
                                rowService.netPrice,
                                rowService.vatPer,
                                rowService.vatPrice,
                                rowService.grandTotalPrice
                              )
                            "
                            @input="calculateService(rowService)"
                            @change="calculateService(rowService)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculateService(rowService)"
                        >
                          {{ rowService.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculateService(rowService)"
                        >
                          {{ rowService.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                               v-if="statusOS == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="ConfirmDel(rowService, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.rowService.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <code>* </code
                  ><label for="branchId">เลือกงานนอกเพื่อเพิ่ม</label>
                  <multiselect
                  :disabled="statusOS != 'DFT'"
                    v-model="serviceSelect"
                    :options="optionsService"
                    :custom-label="customLabelService"
                    @input="popupmodalAddOS(serviceSelect)"
                  >
                  </multiselect>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>

        <!-- //!end card -->
      </div>
    </div>

    <div class="row">
      <div class="card">
        <!--  -->
        <b-overlay
          :show="showOverlayFormOS"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <div class="card-body">
            <h4 class="card-title">
              <h4>
                <strong>สรุปยอด</strong>
              </h4>
            </h4>
            <hr />
            <div class="row">
              <div class="col-md-2">
                รายละเอียด
              </div>
              <div class="col-md-2">
                <div style="display: inline-block">
                  <div class="text-end">
                    ยอดรวมค่าอะไหล่ : <br />
                    ส่วนลดรวม(%) : <br />
                    ส่วนลดรวม(บาท) : <br />
                    ภาษีรวม(%) : <br />
                    ภาษีรวม(บาท) : <br />
                    สุทธิ : <br />
                  </div>
                </div>
              </div>
              <div class="col-md-2 text-end">
                {{ Number(osTotalPrice).toFixed(2).toLocaleString() }} <br />
                {{ parseFloat(dcPerOS).toFixed(2) }}<br />
                {{ Number(osDcPrice).toFixed(2).toLocaleString() }} <br />
                {{ parseFloat(vatPer).toFixed(2) }} <br />
                {{ Number(osVatPrice).toLocaleString() }} <br />
                {{ Number(osGrandTotalPrice).toLocaleString() }}
              </div>
              <div class="col-md-1 text-end">
                บาท<br />
                %<br />
                บาท <br />
                % <br />
                บาท <br />
                บาท
              </div>
              <!-- มูลค่ารวม -->
              <div
                class="col-5 bg-dark text-white m-0"
                style="border-radius:10px;margin:auto;justify "
              >
                <div class="row align-items-center m-0">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-3">
                        มูลค่ารวม :
                      </div>
                      <div class="col-6 font-size-24">
                        {{
                          Number(
                            parseFloat(this.osGrandTotalPrice)
                          ).toLocaleString()
                        }}
                      </div>
                      <div class="col-3">
                        บาท
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br /><br />
            <div class="row">
              <hr />
              <div class="text-end">
                <code v-show="statusOS != 'DFT'"
                  >ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว
                </code>
                <button
                  class="btn btn-success"
                  @click="confirmPutstatusOS()"
                  :disabled="statusOS != 'DFT'"
                >
                  SUBMIT
                </button>
                |

                <button
                  class="btn btn-success"
                  @click="putOS()"
                  :disabled="statusOS == 'APV'"
                >
                  เสร็จสิ้น-กลับสู่หน้าหลัก
                </button>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <b-modal
      title="เพิ่มงานนอก"
      hide-footer
      size="md"
      centered
      ref="modalAddService"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>
            [ {{ serviceSelect.osCode }} ] - {{ serviceSelect.nameTh }}
            <br /><br />
            ราคา : {{ serviceSelect.price }}
          </h5>
          <hr />
        </div>
        <div class="col-md-12">
          <label for="amount">จำนวน</label>
          <input
            type="number"
            v-model="amount"
            class="form-control"
            style="text-align:center;"
          />
        </div>
        <div class="col-md-12 mt-3">
          <label for="amount">%ส่วนลด</label>
          <input
            type="number"
            v-model="dcPer"
            class="form-control"
            style="text-align:center;"
          />
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-lg">
          <div class="text-center">
            <button
              class="btn btn-success w-100"
              @click="postService(serviceSelect)"
            >
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
